import DeletePopover from '@components/DeletePopover'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { Box, Chip, Grid, TextField, Checkbox, Typography } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { Fragment, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { snackActions } from '@helpers/snackbarUtils'

const SessionsCycleJourney = ({
  getNewJourneys,
  // handleEditedJourneys,
  isUpdate,
  journeyIndex,
  data,
  setData
}) => {
  const journey = data[journeyIndex]
  const { t } = useTranslation()

  useEffect(() => {
    if (data && journey.cycleJourneySessions.length === 0) {
      let errores = journey.cycleJourneySessions.map(() => {
        return { from: false, to: false }
      })
      const newJourneys = data.map((session, index) => {
        if (index === journeyIndex) {
          return {
            ...journey,
            cycleJourneySessions: [
              {
                cycleJourneyDateFrom: session.cycleJourneyDateFrom,
                cycleJourneyDateTo: session.cycleJourneyDateTo,
                ...(isUpdate && { idCycleJourney: session.idCycleJourney })
              }
            ]
          }
        }
        return session
      })
      // handleEditedJourneys()
      setData(newJourneys)
    }
  }, [])

  const handleChangeSessions = (cycleJourneyDateFrom, cycleJourneyDateTo) => {
    const newJourneys = data.map((session, index) => {
      if (index === journeyIndex) {
        return {
          ...journey,
          cycleJourneyDateFrom: cycleJourneyDateFrom,
          cycleJourneyDateTo: cycleJourneyDateTo
        }
      }
      return session
    })
    // handleEditedJourneys()
    setData(newJourneys)
  }
  const handleChangeDateFrom = (e, journey, index, idInput, checked) => {
    let fecha = e ? moment(e).format('YYYY-MM-DDTHH:mm') : '';
    // Si la fecha no incluye hora o es 00:00, se establece como 09:00
    if (!fecha.includes('T') || moment(fecha).format('HH:mm') === '00:00') {
        fecha = moment(fecha).hour(9).minute(0).second(0).format('YYYY-MM-DDTHH:mm');
    }
    let errFrom = false
   
    if (
      index > 0 &&
      journey.cycleJourneySessions[index - 1].cycleJourneyDateTo > fecha
    ) {
      snackActions.error(t('TEXT.ERR_DATE'));
      errFrom = true
    }

    if (
      index == 0 &&
      journeyIndex > 0 &&
      data[journeyIndex - 1]?.cycleJourneySessions?.length > 0 &&
      data[journeyIndex - 1].cycleJourneySessions.at(-1).cycleJourneyDateTo >
				fecha
    ) {
      snackActions.error(t('TEXT.ERR_DATE'))
      errFrom = true;
    }

    // Actualiza las fechas de la sesión seleccionada
    let newSessionValues = journey.cycleJourneySessions.map((session, i) => {
      if (i === index) {
        return {
          ...session,
          cycleJourneyDateFrom: fecha,
          cycleJourneyDateTo: session.cycleJourneyDateTo || moment(fecha).hour(18).minute(15).second(0).format('YYYY-MM-DDTHH:mm'),
          errFrom: errFrom
        }
      }
      return session
    })
    
    newSessionValues = newSessionValues.map((j, i) =>
      (i === index && !isUpdate)
        ? {
            ...j,
            cycleJourneyDateTo: moment(
              new Date(j.cycleJourneyDateFrom).setHours(
                18,
                15,
                0
              )
            ).format('YYYY-MM-DDTHH:mm')
          }
        : ((i === index && isUpdate) ? {
          ...j,
          cycleJourneyDateTo: moment(j.cycleJourneyDateFrom).set({
            hour: moment(j.cycleJourneyDateTo).hour(),
            minute: moment(j.cycleJourneyDateTo).minute()
          }).format('YYYY-MM-DDTHH:mm')
        }  : j )
    )

    // Actualiza las fechas del journey con las fechas de la primera sesión
    const newJourneys = getNewJourneys(
      newSessionValues,
      'cycleJourneySessions',
      journeyIndex
    ).map((session, i) => {
      if (i === journeyIndex) {
        return {
          ...session,
          cycleJourneyDateFrom: newSessionValues[0]?.cycleJourneyDateFrom || '', // Sincroniza con la primera sesión
          cycleJourneyDateTo: newSessionValues[0]?.cycleJourneyDateTo || '' // Sincroniza con la primera sesión
        }
      }
      return session
    })
    // handleEditedJourneys()
    setData(newJourneys)
  }

  const handleChangeDateTo = (e, journey, index, idInput, checked) => {
    const [hour, minutes] = e.format('HH:mm').split(':')
    let errTo = false
    if (
      journey.cycleJourneySessions &&
      journey.cycleJourneySessions[index]?.cycleJourneyDateFrom !== undefined
    ) {
      const dateTo = moment(
        new Date(
          journey.cycleJourneySessions[index].cycleJourneyDateFrom
        ).setHours(hour, minutes, 0)
      ).format('YYYY-MM-DDTHH:mm')
      if (journey.cycleJourneySessions[index]?.cycleJourneyDateFrom > dateTo) {
        errTo = true
      }
      if (
        journey.cycleJourneySessions.length > index + 1 &&
        journey.cycleJourneySessions[index + 1].cycleJourneyDateFrom < dateTo
      ) {
        errTo = true
      }
      if (
        data.length > journeyIndex + 1 &&
        data[journeyIndex + 1].cycleJourneySessions[0].cycleJourneyDateFrom &&
        data[journeyIndex + 1].cycleJourneySessions[0].cycleJourneyDateFrom <
          dateTo
      ) {
        errTo = true
      }
    }
    //Si cycleJourneyDateFrom no es undefined, cycleJourneyDateTo tiene como fecha la misma que cycleJourneyDateFrom, ya que una jornada no puede durar m�s de un d�a
    let newSessionValues = journey.cycleJourneySessions.map((j, i) =>
      i === index && j.cycleJourneyDateFrom !== undefined
        ? {
            ...j,
            cycleJourneyDateTo: moment(
              new Date(j.cycleJourneyDateFrom).setHours(hour, minutes, 0)
            ).format('YYYY-MM-DDTHH:mm'),
            errTo: errTo
          }
        : j
    )

    //Si cycleJourneyDateFrom es undefined, cycleJourneyDateTo es la fecha de hoy
    newSessionValues = newSessionValues.map((j, i) =>
      i === index && j.cycleJourneyDateFrom === undefined
        ? {
            ...j,
            cycleJourneyDateTo: moment(
              new Date().setHours(hour, minutes, 0)
            ).format('YYYY-MM-DDTHH:mm'),
            errTo: errTo
          }
        : j
    )

    let newJourneys = getNewJourneys(
      newSessionValues,
      'cycleJourneySessions',
      journeyIndex
    )
    if (checked) {
      newJourneys = newJourneys.map((session, indice) => {
        if (indice === journeyIndex) {
          return {
            ...session,
            cycleJourneyDateTo: newSessionValues[index].cycleJourneyDateTo
          }
        }
        return session
      })
    }
    // handleEditedJourneys()
    setData(newJourneys)
  }

  const addSession = item => {
    let vacias = item.cycleJourneySessions.filter(
      x => x.cycleJourneyDateFrom == '' || x.cycleJourneyDateTo == ''
    )
    if (vacias.length > 0) {
      snackActions.error(t('TEXT.EMPTY_DATE'))
      return
    }

    const newSessionValues = [
      ...item.cycleJourneySessions,
      {
        cycleJourneyDateFrom: '',
        cycleJourneyDateTo: '',
        ...(isUpdate && { idCycleJourney: item.idCycleJourney })
      }
    ]

    const newJourneys = getNewJourneys(
      newSessionValues,
      'cycleJourneySessions',
      journeyIndex
    ).map((session, index) => {
      if (index === journeyIndex) {
        return {
          ...session,
          cycleJourneyDateFrom: newSessionValues[0]?.cycleJourneyDateFrom || '',
          cycleJourneyDateTo: newSessionValues[0]?.cycleJourneyDateTo || ''
        }
      }
      return session
    })

    setData(newJourneys)
  }

  const removeSession = (journey, sessionIndex) => {
    const newSessionValues = journey.cycleJourneySessions.filter(
      (_, index) => index !== sessionIndex
    )
    const newJourneys = getNewJourneys(
      newSessionValues,
      'cycleJourneySessions',
      journeyIndex
    )
    // handleEditedJourneys()
    setData(newJourneys)
  }
  return (
    <div style={{border: '1px solid #0085B4', padding:'10px 10px 10px 5px', borderRadius: '10px', backgroundColor: 'rgba(0, 133, 180, 0.05)'}}>
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <Typography variant='caption' component='div'>
            {t('CYCLES.JOURNEYS.DATETIME_START')}
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant='caption' component='div'>
            {t('CYCLES.JOURNEYS.TIME_END')}
          </Typography>
        </Grid>
      </Grid>

      {journey.cycleJourneySessions.map((session, i) => {
        return (
          <Grid container spacing={0.5} mb={1} key={`cycleJourneyTarget${i}`}>
            <Grid item xs={1}>
              <Checkbox
                edge='start'
                checked={
                  journey.cycleJourneyDateFrom ==
                    session.cycleJourneyDateFrom &&
                  journey.cycleJourneyDateTo == session.cycleJourneyDateTo
                }
                disableRipple
                onChange={() =>
                  handleChangeSessions(
                    session.cycleJourneyDateFrom,
                    session.cycleJourneyDateTo
                  )
                }
              />
            </Grid>

            <Grid item xs={6}>
              <DateTimePicker
                sx={{
                  backgroundColor: 'white !important'
                }}
                className={session?.errFrom ? 'Mui-error' : ''}
                name='cycleJourneyDateFrom'
                slotProps={{
                  textField: {
                    size: 'small',
                    color: session?.errTo ? 'error' : 'primary'
                  }
                }}
                format='DD/MM/YYYY HH:mm' // formato de fecha y hora válido para el locale en español
                value={
                  session?.cycleJourneyDateFrom
                    ? moment(session?.cycleJourneyDateFrom)
                    : null // Usa null en lugar de cadena vacía '' para el valor de fecha y hora
                }
                onChange={e =>
                  handleChangeDateFrom(
                    e,
                    journey,
                    i,
                    `dateFrom-${journey.idCycleJourney}-${i}`,
                    journey.cycleJourneyDateFrom ===
                      session.cycleJourneyDateFrom &&
                      journey.cycleJourneyDateTo === session.cycleJourneyDateTo
                  )
                }
              />
              {/* <TextField
                error={session?.errFrom}
                fullWidth
                size='small'
                type='datetime-local'
                name='cycleJourneyDateFrom'
                id={`dateFrom-${journey.idCycleJourney}-${i}`}
                value={
                  session?.cycleJourneyDateFrom
                    ? moment(session?.cycleJourneyDateFrom).format(
                        'YYYY-MM-DDTHH:mm'
                      )
                    : ''
                }
                onChange={e =>
                  handleChangeDateFrom(
                    e,
                    journey,
                    i,
                    `dateFrom-${journey.idCycleJourney}-${i}`,
                    journey.cycleJourneyDateFrom ===
                      session.cycleJourneyDateFrom &&
                      journey.cycleJourneyDateTo === session.cycleJourneyDateTo
                  )
                }
              /> */}
            </Grid>

            <Grid item xs={4}>
              {
                <>
                  {/*  <TextField
                    fullWidth
                    size='small'
                    type='time'
                    name='cycleJourneyDateTo'
                    id={`dateTo-${journey.idCycleJourney}-${i}`}
                    value={
                      session?.cycleJourneyDateTo
                        ? moment(session?.cycleJourneyDateTo).format('HH:mm')
                        : ''
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={e => {
                      handleChangeDateTo(
                        e,
                        journey,
                        i,
                        `dateTo-${journey.idCycleJourney}-${i}`,
                        journey.cycleJourneyDateFrom ===
                          session.cycleJourneyDateFrom &&
                          journey.cycleJourneyDateTo ===
                            session.cycleJourneyDateTo
                      )
                    }}
                  /> */}
                  <TimePicker
                    sx={{
                      backgroundColor: 'white !important'
                    }}
                    name='cycleJourneyDateTo'
                    className={session?.errTo ? 'Mui-error' : ''}
                    slotProps={{
                      textField: {
                        size: 'small',
                        color: session?.errTo ? 'error' : 'primary'
                      }
                    }}
                    id={`dateTo-${journey.idCycleJourney}-${i}`}
                    value={
                      session?.cycleJourneyDateTo
                        ? moment(session?.cycleJourneyDateTo)
                        : null
                    }
                    onChange={e => {
                      handleChangeDateTo(
                        e,
                        journey,
                        i,
                        `dateTo-${journey.idCycleJourney}-${i}`,
                        journey.cycleJourneyDateFrom ===
                          session.cycleJourneyDateFrom &&
                          journey.cycleJourneyDateTo ===
                            session.cycleJourneyDateTo
                      )
                    }}
                  />
                </>
              }
            </Grid>
            <Grid item xs={1}>
              <Box
                style={{
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <DeletePopover
                  remove={() => {
                    removeSession(journey, i)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      })}
      <Box display='flex' justifyContent='start' mt={2}>
        <Chip
          size='small'
          onClick={() => {
            addSession(journey)
          }}
          label={t('LABEL.ADD_ELEMENT')}
          color='primary'
          sx={{ padding: 0 }}
          icon={<AddCircleIcon />}
        />
      </Box>
    </div>
  )
}

export default SessionsCycleJourney
