import { Fragment, useState } from 'react'
import DeletePopover from '@components/DeletePopover'
import DialogEmail from '@components/DialogEmail'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import { useCommonStyles } from '@styles/common.style'
import { useTranslation } from 'react-i18next'

const EmailConfiguration = ({
  setJourney,
  setFormData,
  formData,
  journey,
  categories,
  emailList,
  showEmailItems,
  allEmails
}) => {
  const classes = useCommonStyles()
  const { t } = useTranslation()
  const [showEmailModal, setShowEmailModal] = useState({
    open: false,
    loading: true,
    content: {}
  })
  
  const [intervalId, setIntervalId] = useState(null); // Estado para manejar el intervalo
  const [localMailingDays, setLocalMailingDays] = useState({}); // Estado local para mailingDays
  
  const daysToText = days => {
    if (days === 0) {
      return t('CONFIG_JOURNEYS.EMAIL.SAME_DAY')
    }
    if (days < 0) {
      return `${Math.abs(days)} ${
        Math.abs(days) === 1
          ? t('CONFIG_JOURNEYS.EMAIL.DAY')
          : t('CONFIG_JOURNEYS.EMAIL.DAYS')
      } ${t('CONFIG_JOURNEYS.EMAIL.BEFORE')}`
    }
    if (days > 0) {
      return `${days} ${
        days === 1
          ? t('CONFIG_JOURNEYS.EMAIL.DAY')
          : t('CONFIG_JOURNEYS.EMAIL.DAYS')
      }  ${t('CONFIG_JOURNEYS.EMAIL.AFTER')}`
    }
  }
  //TODO REVISAR.
  const categoriesFiltered = categories?.filter(c => c.visibleGenericType)
  // console.log('mail', journey)
  // console.log('allmail', allEmails)
  const addEmail = item => {
    const newEmailValues = [
      ...item.cycleTypeJourneyMailings,
      {
        idCycleTypesJourneysMailing: 0,
        idCycleTypeJourney: 0,
        idMailing: emailList[categoriesFiltered[0].idMailingType][0].idMailing,
        mailingDays: 0
      }
    ]

    setJourney({
      ...journey,
      cycleTypeJourneyMailings: newEmailValues
    })
  }
  const removeEmail = (journey, emailIndex) => {
    // console.log(journey, emailIndex, journey.cycleTypeJourneyMailings)
    const newEmailValues = journey.cycleTypeJourneyMailings.filter(
      (_, index) => index !== emailIndex
    )
    // console.log(newEmailValues)
    setJourney({
      ...journey,
      cycleTypeJourneyMailings: newEmailValues
    })
  }

  const handleChangeEmail = (e, journey, index, isCategory) => {
    const newEmailValues = journey.cycleTypeJourneyMailings.map((email, i) => {
      if (i === index) {
        return {
          ...email,
          idMailing: isCategory
            ? emailList[e.target.value][0].idMailing
            : e.target.value
        }
      }
      return email
    })

    setJourney({
      ...journey,
      cycleTypeJourneyMailings: newEmailValues
    })
  }

  const handleChangeDays = (value, journey, index) => {
    const newEmailValues = journey.cycleTypeJourneyMailings.map((email, i) => {
      if (i === index) {
        return {
          ...email,
          mailingDays: value
        }
      }
      return email
    })

    setJourney({
      ...journey,
      cycleTypeJourneyMailings: newEmailValues
    })
  }
  const getIdMailingType = idMailing => {
    const { idMailingType } = allEmails?.find(
      email => email.idMailing === idMailing
    )
    return idMailingType
  }
  
  const handleMouseDown = (change, journey, index) => {
    // Cambiar días inmediatamente al hacer clic (un día por pulsación)
    setLocalMailingDays(prev => ({
        ...prev,
        [index]: (prev[index] !== undefined
            ? prev[index]
            : journey.cycleTypeJourneyMailings[index].mailingDays) + change,
    }));

    // Iniciar un intervalo para cambiar días continuamente mientras se mantiene pulsado
    const timeoutId = setTimeout(() => {
        const id = setInterval(() => {
            setLocalMailingDays(prev => ({
                ...prev,
                [index]: (prev[index] !== undefined
                    ? prev[index]
                    : journey.cycleTypeJourneyMailings[index].mailingDays) + change,
            }));
        }, 20); // Cambia cada 20ms para hacerlo más rápido
        setIntervalId(id); // Guarda el ID del intervalo en el estado
    }, 900); // Espera 900ms antes de iniciar el intervalo
    setIntervalId(timeoutId); // Guarda el ID del timeout en el estado
};

  const handleMouseUp = (journey, index) => {
    // Detener el intervalo o timeout cuando se suelta el botón
    if (intervalId) {
      clearInterval(intervalId); // Limpia el intervalo
      clearTimeout(intervalId); // Limpia el timeout
      setIntervalId(null); // Resetea el estado del intervalo
    }

    if (localMailingDays[index] !== undefined) {
      handleChangeDays(localMailingDays[index], journey, index);

      setLocalMailingDays(prev => {
        const updatedLocal = { ...prev };
        delete updatedLocal[index];
        return updatedLocal;
      });
    }
  };

  return (
    <Fragment>
      {showEmailModal.open && (
        <DialogEmail
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
        />
      )}

      <Typography
        textAlign='center'
        color='white'
        variant='subtitle1'
        mb={2}
        className={classes.cardTitle}
      >
        {t('CONFIG_JOURNEYS.EMAIL.CONFIG_EMAIL').toUpperCase()}
      </Typography>
      {/* MOSTRAR SI EL ARRAY TIENE CONTENIDO */}
      {journey?.cycleTypeJourneyMailings?.length !== 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.EMAIL.CATEGORY')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.EMAIL.EMAIL')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption' component='div'>
              {t('CONFIG_JOURNEYS.EMAIL.TIME_SEND')}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography variant='caption' component='div'>
          {t('CONFIG_JOURNEYS.EMAIL.NO_ASSIGNED_EMAILS')}
        </Typography>
      )}

      {journey.cycleTypeJourneyMailings?.map((mail, index) => {
        return (
          <Grid container spacing={1} mb={1} key={index}>
            <Grid item xs={3}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                value={getIdMailingType(mail.idMailing) || ''}
                onChange={e => handleChangeEmail(e, journey, index, true)}
              >
                {categoriesFiltered.map(category => {
                  return (
                  <MenuItem
                    className={classes.menuItem}
                    value={category.idMailingType}
                    key={category.idMailingType}
                  >
                    {category.mailingTypeName}
                  </MenuItem>
                )})}
              </Select>
            </Grid>

            <Grid item xs={4}>
              <Select
                className={classes.select}
                fullWidth
                size='small'
                name='idMailing'
                value={mail.idMailing}
                renderValue={selected => {
                  const name = allEmails.find(e => e.idMailing === selected)
                  return name.mailingName
                }}
                onChange={e => handleChangeEmail(e, journey, index)}
              >
                {showEmailItems({
                  key: getIdMailingType(mail.idMailing),
                  setShowEmailModal
                })}
              </Select>
            </Grid>
            <Grid item xs={4}>
              <Box display='flex' alignItems='center'>
                <ButtonGroup
                  disableElevation
                  variant='contained'
                  fullWidth
                  style={{ height: 39 }}
                >
                  <Button
                    sx={{
                      width: 25
                    }}
                    onMouseDown={() => handleMouseDown(-1, journey, index)} // Disminuir días
                    onMouseUp={() => handleMouseUp(journey, index)} // Detener el intervalo al soltar el botón
                    onMouseLeave={() => handleMouseUp(journey, index)} // Detener el intervalo si el cursor abandona el botón
                  >
                    -
                  </Button>
                  <Button
                    disabled
                    sx={{
                      border: 'none',
                      textTransform: 'none',
                      '&:disabled': {
                        color: 'black',
                        padding: 1,
                        fontSize: 11.5,
                        lineHeight: 1.3
                      }
                    }}
                  >
                    {daysToText(
                      localMailingDays[index] !== undefined
                        ? localMailingDays[index]
                        : mail.mailingDays
                    )}
                  </Button>
                  <Button
                    sx={{
                      width: 50,
                    }}
                    onMouseDown={() => handleMouseDown(1, journey, index)} // Aumentar días
                    onMouseUp={() => handleMouseUp(journey, index)} // Detener el intervalo al soltar el botón
                    onMouseLeave={() => handleMouseUp(journey, index)} // Detener el intervalo si el cursor abandona el botón
                  >
                    +
                  </Button>
                </ButtonGroup>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box
                style={{
                  height: '100%',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                <DeletePopover
                  remove={() => {
                    removeEmail(journey, index)
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        )
      })}

      <Box display='flex' justifyContent='start' mt={2}>
        <Chip
          size='small'
          onClick={() => {
            addEmail(journey)
          }}
          label={t('CONFIG_JOURNEYS.EMAIL.ADD')}
          color='primary'
          sx={{ padding: 0 }}
          icon={<AddCircleIcon />}
        />
      </Box>
    </Fragment>
  )
}

export default EmailConfiguration
