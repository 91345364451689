import React, { useState, useEffect } from 'react'
import LogoCampus from '@assets/images/logoCampus.png'
import { useForm } from '@hooks/useForm'
import {
    Box,
    Button,
    Grid,
    TextField,
    Typography,
    Select,
    MenuItem,
    IconButton,
    InputAdornment
} from '@mui/material'

import serviceCycleCompanies from '@services/serviceCycleCompanies'
import serviceCycles from '@services/serviceCycles'
import serviceParticipants from '@services/serviceParticipants'
import { useStyles } from '@styles/signUpParticipants.style'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { snackActions } from '@helpers/snackbarUtils'
import Dialog from '@components/Dialog'
import AvatarParticipant from '@components/AvatarParticipant'
import { Link } from 'react-router-dom'
import { useHandleImages } from "@hooks/useHandleImages";
import Loading from '@components/Loading';
import { ArrowBack } from '@mui/icons-material';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ChangeImageParticipant from '@components/ChangeImageParticipant'

const SignUpParticipant = ({ flagOptions }) => {
    const {
        state: { data: dataInicio }
      } = useLocation()
    const params = useParams()
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()
    const classes = useStyles()
    const patternNumberKeysCycle = new RegExp('^[0-9]{3}$')
    const patternNumberPhone = new RegExp('^(\\+[0-9]{1,3})?\\s?[0-9]{9,16}$')
    const patternNumberZipCode = new RegExp('^[0-9]{5,7}$') // Se permiten códigos postales desde 5 a 7 cifras
    const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const patternName = new RegExp('^[a-zA-Z\\u00C0-\\u017F\\s]+$')
    const [buttonPressed, setButtonPressed] = useState(false)
    const [idCycle, setIdCycle] = useState('-1')
    const [companies, setCompanies] = useState([])
    const [idCompany, setIdCompany] = useState('')
    const [nameCompany, setNameCompany] = useState('')
    const [viewDialog, setViewDialog] = useState(false)
    const [login, setLogin] = useState(false)
    const [participantExists, setParticipantExists] = useState(false)
    const [isAcceptGdpr, setIsAcceptGdpr] = useState(false)
    const [isShareEmail, setIsShareEmail] = useState(true)
    const [isSharePhone, setIsSharePhone] = useState(true)
    const [screenShown, setScreenShown] = useState(1)
    const [companySelected, setCompanySelected] = useState(null)
    const { manageImages, infoData } = useHandleImages()
    const [isLoading, setIsLoading] = useState(false)
    const [participantImage, setParticipantImage] = useState(null);
    const [openDialogPicture, setOpenDialogPicture] = useState(false);

    const fetchParticipantImage = async (idParticipant) => {
        try {
            const { data, error } = await serviceParticipants.getParticipantImage(idParticipant);
            if (!error && data) {
                setParticipantImage(`data:image/png;base64,${data}`);
            }
        } catch (error) {
            console.error(`Error al cargar la imagen del participante: ${error}`);
        }
    };


    
    const [showPassword, setShowPassword] = useState(false)

    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    }
  
    const handleMouseDownPassword = (e) => {
      e.preventDefault();
    }

    const {
        formData,
        setFormData,
        nameParticipant,
        surnameParticipant,
        registreEmail,
        confirmRegistreEmail,
        registrePassword,
        cycleFirstKey,
        cycleSecondKey,
        jobPositionParticipant,
        phoneParticipant,
        zipCodeParticipant,
        nickNameParticipant,
        otherCompanyParticipant,
        handleChange
    } = useForm({
        nameParticipant: '',
        surnameParticipant: '',
        registreEmail: '',
        confirmRegistreEmail: '',
        registrePassword: '',
        cycleFirstKey: params.key.substring(0, 3),
        cycleSecondKey: params.key.substring(3, 6),
        jobPositionParticipant: '',
        phoneParticipant: '',
        zipCodeParticipant: '',
        nickNameParticipant: '',
        participantImage: '',
        isShareEmail: true,
        isSharePhone: true,
        loginMode: 'LoginParticipant',
        otherCompanyParticipant: '',
    })

    useEffect(() => {
        if (companies.length === 0) {
            //Si no hubo error, es que se ha encontrado el ciclo
            if (!dataInicio.error) {
                setIdCycle(dataInicio.idCycle)
                serviceCycleCompanies
                    .getCycleCompaniesList(dataInicio.idCycle)
                    .then(response2 => {
                        if (!response2.error) {
                            setCompanies(response2.data)
                }})
            } else {
                setCompanies([])
            }
        }
    }, [])


    useEffect(() => {
        if (companySelected) {
            setIdCompany(companySelected.idCompany)
            setNameCompany(companySelected.companyName)
            setFormData(prevState => ({
                ...prevState,
                idCompanyParent: companySelected.idCompany
            }))
        }
    }, [companySelected])


    useEffect(() => {
        if (infoData?.participantImage !== undefined && infoData?.participantImage !== null && infoData?.participantImage.length !== 0) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    participantImage: infoData.participantImage,
                };
            });
        }
    }, [infoData])

    useEffect(() => {
        if (screenShown === 3 && formData.idParticipant) {
            fetchParticipantImage(formData.idParticipant);
        }
    }, [screenShown, formData.idParticipant]);


    const handleChecks = e => {
        const checkName = e.target.name
        if (checkName === 'gdpr') {
            setIsAcceptGdpr(current => !current)
        } else if (checkName === 'sharePhone') {
            setIsSharePhone(current => !current)
        } else if (checkName === 'shareEmail') {
            setIsShareEmail(current => !current)
        }
    }

    const handleSelect = e => {
        setCompanySelected(
            companies.find(company => company?.companyName === e.target.value)
        )
    }

    const handleScreen = async e => {
        const btnType = e.target.attributes.id.value
        const nextscreen =
            btnType === 'btnNext' && screenShown !== 3
                ? screenShown + 1
                : btnType === 'btnPrev' && screenShown !== 1
                    ? screenShown - 1
                    : screenShown

        let error = false;
        if (btnType === 'btnNext') error = await validateRegitreInputs();

        if (!error) setScreenShown(nextscreen)
    }

    const checkParticipant = async () => {
        try {
            const response = await serviceParticipants.checkParticipants({
                loginEmail: formData.registreEmail,
                loginPassword: formData.registrePassword,
                idCycle: idCycle
            });

            let result = '';
            if (!response.error) {
                if (response.data.cycleParticipant !== null) {
                    setLogin(true);
                    result = 'login';
                } else if (response.data.participant !== null) {
                    setParticipantExists(true);
                    result = 'participantExists';
                    setFormData((prevState) => ({
                        ...prevState,
                        nameParticipant: response.data?.participant.participantName,
                        surnameParticipant: response.data?.participant.participantSurname
                    }));
                }
            } else {
                result = "error"
            }
            return result; // Devolvemos el resultado correctamente
        } catch (error) {
            // Manejar cualquier error en la petición
            console.error(error);
            return ''; // En caso de error, devolvemos un valor vacío o algún valor que indique error, según lo que necesites.
        }
    };

    const handleViewDialog = () => {
        setViewDialog(true)
    }

    const validateRegitreInputs = async () => {
        let error = false;
        switch (screenShown) {
            case 1:
                error = validateScreen1();
                if (!error) {
                    let result = await checkParticipant();
                    if (result === 'login' || result === 'error')
                        error = true; //Si hay que mostrar el login o ha dado error el checkParticipant seteamos que hay error para que no pase de pantalla.
                }
                break;
            case 2:
                error = validateScreen2();
                break;
            case 3:
                error = false;
                break;
            default:
                error = false;
        }
        return error;
    };

    const validateScreen1 = () => {
        let error = false;
        //Primero validamos que el email venga relleno y con formato correcto
        if (registreEmail === '' || !patternEmail.test(registreEmail.toLowerCase())) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.TYPE_EMAIL'))
        }
        //Validamos que el mail de confirmación venga relleno y con formato correcto.
        if (confirmRegistreEmail === '' || !patternEmail.test(confirmRegistreEmail.toLowerCase())) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.TYPE_EMAIL'))
        }
        //Validamos que coinciden el email y la confirmacion del mismo
        if (registreEmail.toLowerCase() !== confirmRegistreEmail.toLowerCase()) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.SAME_EMAIL_CONFIRM_EMAIL'))
        }
        //Validamos que la password venga rellena.
        if (registrePassword === '') {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.PASSWORD'))
        }

        return error;
    }

    const validateScreen2 = () => {
        let error = false;
        //Validamos el nombre
        if (nameParticipant === '' || !patternName.test(nameParticipant)) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.TYPE_NAME'))
        }
        //Validamos el apellido
        if (surnameParticipant !== '' && !patternName.test(surnameParticipant)) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.TYPE_SURNAME'))
        }
        //Validamos el nombre del cartel
        if (nickNameParticipant === '') {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.NICKNAME_PARTICIPANT'))
        }
        //Validamos la empresa
        if (idCompany === '') {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.SELECT_COMPANY'))
        }
        //Tambien tenemos que validar que si pones 'OTRA' que tenga el campo del nombre de la otra empresa.
        if (idCompany === 999999 && (otherCompanyParticipant === '' || typeof otherCompanyParticipant === 'undefined' || !patternName.test(otherCompanyParticipant))) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.OTHER_COMPANY'))
        }
        //Validamos el Telefono de contacto
        if (phoneParticipant === '' || !patternNumberPhone.test(phoneParticipant)) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.PHONE_PARTICIPANT'))
        }
        //Validamos el Codigo postal
        if (zipCodeParticipant === '' || !patternNumberZipCode.test(zipCodeParticipant)) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.ZIP_CODE_PARTICIPANT'))
        }
        //Validamos la GDPR
        if (!isAcceptGdpr) {
            error = true;
            snackActions.error(t('LOGIN.SIGN_UP.ERROR.GDPR'))
        }

        return error;
    }


    const handleRegistreSubmit = async e => {
        e.preventDefault()
        if (!buttonPressed) {
            setIsLoading(true);
            setButtonPressed(true);
            let dataSignUpParticipant = {
                ParticipantName: nameParticipant,
                ParticipantSurname: surnameParticipant,
                ParticipantEmail: registreEmail,
                IdCompany: idCompany,
                ParticipantPosition: jobPositionParticipant,
                ParticipantZip: zipCodeParticipant,
                ParticipantPhone: phoneParticipant,
                ParticipantNickName: nickNameParticipant,
                ParticipantSharePhone: isSharePhone,
                ParticipantShareEmail: isShareEmail,
                ParticipantImage: participantImage,
                ParticipantPassword: registrePassword,
                ParticipantOtherCompany: otherCompanyParticipant
            }
            let dataRequestSignUpParticipant = {
                idCycle: idCycle,
                data: dataSignUpParticipant
            }

            serviceParticipants.signUpParticipant(dataRequestSignUpParticipant)
                .then(response => {
                    if (!response.error) {
                        if (!participantExists) snackActions.success(t('LOGIN.SIGN_UP.OK.MESSAGE'))
                        setCompanies([])
                        setIdCompany('')
                        navigate('/participants')
                    }
                    setIsLoading(false)
                })
        }
    }

    if (isLoading) {
      return <Loading />;
  }

    return (
        <form onSubmit={handleRegistreSubmit} className={classes.card}>
            <div className={classes.header}>
                <img src={LogoCampus} alt='logo' className={classes.image}></img>
                <Typography className={classes.title}>
                    {t('LOGIN.SIGN_UP.TITLE_PARTICIPANTS')}
                </Typography>
            </div>
            <Grid container justifyContent={'center'} spacing={2}>
                {screenShown === 1 && (
                    <>
                        <Grid item xs={12} mb={-0.75}>
                            <TextField
                                error={!patternEmail.test(registreEmail.toLowerCase())}
                                id='registreEmail'
                                label={t('LABEL.EMAIL')}
                                variant='filled'
                                name='registreEmail'
                                type='text'
                                fullWidth
                                value={registreEmail.toLowerCase()}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} mb={-0.75}>
                            <TextField
                                error={!patternEmail.test(confirmRegistreEmail.toLowerCase())}
                                id='confirmRegistreEmail'
                                label={t('LABEL.CONFIRM_EMAIL')}
                                variant='filled'
                                name='confirmRegistreEmail'
                                type='text'
                                fullWidth
                                value={confirmRegistreEmail.toLowerCase()}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} mb={1}>
                            <TextField
                                // error={!patternEmail.test(registreEmail)}
                                id='registrePassword'
                                label={t('LABEL.PASSWORD')}
                                variant='filled'
                                name='registrePassword'
                                type={showPassword ? 'text' : 'password'}
                                fullWidth
                                value={registrePassword}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 100 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge='end'                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid display='flex' marginX={2} mb={2}>
                            <Typography variant='subtitle1' style={{ lineHeight: '0.5em' }} sx={{ fontSize: '1.1em !important' }}>
                                {' '}
                                <Typography
                                    color='primary'
                                    display={'inline'}
                                    style={{ fontWeight: 'bold' }}
                                >
                                    {t('LOGIN.SIGN_UP.ATTENTION').toUpperCase()}:{' '}
                                </Typography>
                                {t('LOGIN.SIGN_UP.PARTICIPANTS_TIP')}
                            </Typography>
                        </Grid>
                        <Box mt={1} width='100%' display='flex' sx={{ justifyContent: 'flex-end' }}>
                            <Typography
                                component={Link}
                                className={classes.subtitle}
                                to='/lostPassword'
                                color='secondary'
                                fontSize={16}
                            >
                                {t('LOGIN.TAG_LOST_PASSWORD')}
                            </Typography>
                        </Box>
                        {login && (
                            <Grid display='flex' marginX={2} marginY={2}>
                                <Typography variant='subtitle1' style={{ lineHeight: '1.2em', color: 'red' }}>
                                    {t('LOGIN.SIGN_UP.ERROR.USER_EXISTS')}
                                </Typography>
                                <Link to={'/login'}>
                                    <Button
                                        id='btnLogin'
                                        variant='contained'
                                        to='/login'
                                        size='large'
                                        order='2'
                                    >
                                        {t('BUTTON.LOGIN')}
                                    </Button>
                                </Link>
                            </Grid>
                        )}
                    </>
                )}

                {screenShown === 2 && (
                    <>
                        <Grid item xs={12} mb={-0.75}>
                            <TextField
                                error={!patternName.test(nameParticipant)}
                                id='nameParticipant'
                                label={t('LABEL.NAME')}
                                variant='filled'
                                name='nameParticipant'
                                type='text'
                                fullWidth
                                disabled={participantExists}
                                value={nameParticipant}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} mb={-0.75}>
                            <TextField
                                error={!patternName.test(surnameParticipant)}
                                id='surnameParticipant'
                                label={t('LABEL.SURNAME')}
                                variant='filled'
                                name='surnameParticipant'
                                type='text'
                                fullWidth
                                disabled={participantExists}
                                value={surnameParticipant}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} mb={0}>
                            <TextField
                                error={nickNameParticipant === ''}
                                id='nickNameParticipant'
                                label={t('LOGIN.SIGN_UP.NICKNAME_PARTICIPANT')}
                                variant='filled'
                                name='nickNameParticipant'
                                type='text'
                                fullWidth
                                value={nickNameParticipant}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12} mb={-0.75}>
                            <Typography color='primary' variant='subtitle1' sx={{ fontSize: '1.1em !important' }}>
                                {t('LOGIN.SIGN_UP.SELECT_COMPANY_NAME')}:
                            </Typography>
                            <Select
                                // error={idCompany === ''}
                                fullWidth
                                id='idCompany'
                                value={nameCompany || ''}
                                onChange={handleSelect}
                                size='small'
                                name='companies'
                                displayEmpty
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return t('LOGIN.SIGN_UP.DEPLOY');  // Muestra "Desplegar"
                                    }
                                    return selected;
                                }}
                            >
                              {companies.map(company => (
                                  <MenuItem key={company.idCompany} value={company.companyName}>
                                      {company.companyName}
                                  </MenuItem>
                              ))}
                            </Select>
                        </Grid>
                        {idCompany === 999999 && (
                            <Grid item xs={12} mb={-0.75}>
                                <TextField
                                    error={!patternName.test(otherCompanyParticipant)}
                                    id='otherCompanyParticipant'
                                    label={t('LOGIN.SIGN_UP.OTHER_COMPANY')}
                                    variant='filled'
                                    name='otherCompanyParticipant'
                                    type='text'
                                    fullWidth
                                    value={otherCompanyParticipant}
                                    onChange={handleChange}
                                    autoComplete='off'
                                    size='small'
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                //error={!patternName.test(jobPositionParticipant)}
                                id='jobPositionParticipant'
                                label={t('LOGIN.SIGN_UP.JOB_POSITION_PARTICIPANT')}
                                variant='filled'
                                name='jobPositionParticipant'
                                type='text'
                                fullWidth
                                value={jobPositionParticipant}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={6} mb={-0.75}>
                            <TextField
                                error={!patternNumberPhone.test(phoneParticipant)}
                                id='phoneParticipant'
                                label={t('LOGIN.SIGN_UP.PHONE_PARTICIPANT')}
                                variant='filled'
                                name='phoneParticipant'
                                type='text'
                                fullWidth
                                value={phoneParticipant}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 20 }}
                            />
                        </Grid>
                        <Grid item xs={6} mb={1}>
                            <TextField
                                error={!patternNumberZipCode.test(zipCodeParticipant)}
                                id='zipCodeParticipant'
                                label={t('LOGIN.SIGN_UP.ZIP_CODE_PARTICIPANT')}
                                variant='filled'
                                name='zipCodeParticipant'
                                type='text'
                                fullWidth
                                value={zipCodeParticipant}
                                onChange={handleChange}
                                autoComplete='off'
                                size='small'
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                        <Grid item xs={12} mb={1}>
                            <Typography
                                variant='caption'
                                component='div'
                                style={{
                                    fontSize: '0.9em',
                                    lineHeight: '1.2em'
                                }}>
                                <input
                                    type='checkbox'
                                    defaultChecked={isAcceptGdpr}
                                    value={isAcceptGdpr}
                                    onChange={e => handleChecks(e)}
                                    id='gdpr'
                                    name='gdpr'
                                />
                                &nbsp;
                                {t('LOGIN.SIGN_UP.CHECK.GDPR_PARTICIPANT')}
                                &nbsp;

                                <b
                                    style={{
                                        color: '#3F85B5',
                                        fontSize: '0.9em',
                                        cursor: 'pointer',
                                        textDecoration: 'underline'
                                    }}
                                    onClick={handleViewDialog}
                                >
                                    {t('LOGIN.SIGN_UP.CHECK.SHOW_LEGAL_NOTICE')}
                                </b>

                            </Typography>
                            <Dialog
                                open={viewDialog}
                                close={() => setViewDialog(false)}
                                title={t('TEXT.LEGAL_NOTICE')}
                                width={300}
                                content={
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        style={{ fontSize: '14px' }}
                                    >
                                        {t('TEXT.CONTENT_LEGAL_NOTICE')}
                                    </Typography>
                                }
                            />
                        </Grid>
                    </>
                )}

                {screenShown === 3 && (
                    <>
                        <Grid
                            item
                            sx={{
                              height: '200px',
                              width: '150px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              overflow: 'hidden',
                              padding: participantImage ? '0' : '20px', // Espacio adicional cuando no hay imagen
                              //border: '2px dashed #b0b0b0', // Bordes para la cámara, solo cuando no hay imagen
                            }}
                          >
                            <Box sx={{ height: '100%', width: '100%' }}>
                              <AvatarParticipant
                                image={participantImage}
                                idbutton={'upload-button-participantImage'}
                                onOpenDialog={() => setOpenDialogPicture(true)} // Abre el Dialog
                              />
                            </Box>
                        </Grid>
                        <Grid item xs={9} mt={2} mb={2} sx={{ padding: '0px !important', display: 'flex', justifyContent: 'center' }}>
                            <Typography className={classes.subtitle2} sx={{ fontSize: '1.1em !important' }}>
                                {t('LOGIN.SIGN_UP.ADD_PIC')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={1} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='caption' component='div' sx={{ fontSize: '1.1em !important' }}>
                                <input
                                    type='checkbox'
                                    defaultChecked={isShareEmail}
                                    value={isShareEmail}
                                    onChange={e => handleChecks(e)}
                                    id='shareEmail'
                                    name='shareEmail'
                                />
                                &nbsp;{t('LOGIN.SIGN_UP.CHECK.SHARE_EMAIL_PARTICIPANT')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography variant='caption' component='div' sx={{ fontSize: '1.1em !important' }}>
                                <input
                                    type='checkbox'
                                    defaultChecked={isSharePhone}
                                    value={isSharePhone}
                                    onChange={e => handleChecks(e)}
                                    id='sharePhone'
                                    name='sharePhone'
                                />
                                &nbsp;{t('LOGIN.SIGN_UP.CHECK.SHARE_PHONE_PARTICIPANT')}
                            </Typography>
                        </Grid>
                    </>
                )}
            </Grid>

            <Box
                xs={12}
                mb={1}
                mt={1.5}
                display='flex'
                justifyContent='space-between'
                flexDirection={screenShown === 1 ? 'row-reverse' : 'row'}
            >
                {screenShown !== 1 && (
                    <Button
                        id='btnPrev'
                        variant='contained'
                        onClick={e => handleScreen(e)}
                        size='large'
                        startIcon={screenShown === 3 ? <ArrowBack /> : null} //Si no es la página 3 no sale la flecha
                    >
                        {screenShown === 3 ? t('TEXT.BACK') : t('BUTTON.PREVIOUS')}
                    </Button>
                )}
                {screenShown !== 3 && !login && (
                    <Button
                        id='btnNext'
                        variant='contained'
                        onClick={e => handleScreen(e)}
                        size='large'
                        order='2'
                    >
                        {t('BUTTON.NEXT')}
                    </Button>
                )}
                {screenShown === 3 && (
                    <Button
                        disabled={buttonPressed || !isAcceptGdpr}
                        variant='contained'
                        type='submit'
                        size='large'
                        order='1'
                    >
                        {t('BUTTON.SIGN_UP')}
                    </Button>
                )}
            </Box>

            {openDialogPicture && (
              <Dialog
                centerContent
                open={openDialogPicture}
                hasCloseBtn={false}
                width="auto"
                height="65%"
                title={t('PARTICIPANTS.ADD_PICTURE')}
                close={() => setOpenDialogPicture(false)}
                content={
                  <Grid 
                    item 
                    xs={12} 
                    display={'flex'} 
                    flexDirection={'column'} 
                    alignItems={'center'} 
                    justifyContent={'center'} 
                    pl={1} 
                    pr={1} 
                    sx={{
                      width: '300px',
                      height: 'auto'
                    }}
                  >
                    <ChangeImageParticipant
                      idParticipant={formData.idParticipant}
                      imageName="participantImage"
                      onImageSave={(newImage) => {
                        setParticipantImage(newImage);
                        setFormData(prevState => ({
                          ...prevState,
                          participantImage: newImage,
                        }));
                        setOpenDialogPicture(false);
                      }}
                      localImage={participantImage}
                    />
                  </Grid>
                }
                actions={
                  <Box display="flex" alignItems="center">
                    <Button variant="contained" onClick={() => setOpenDialogPicture(false)}>
                      {t('BUTTON.CLOSE')}
                    </Button>
                  </Box>
                }
              />
            )}
        </form>
    )
}

export default SignUpParticipant
